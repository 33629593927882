import { isEqualArgs } from 'core/comparer';
export function memoizeOne(fn) {
    let lastArgs = null;
    let lastResult;
    return (...args) => isEqualArgs(lastArgs, args)
        ? lastResult
        : (lastArgs = args) && (lastResult = fn(...args));
}
export function memoizeOneFactory(fn) {
    return () => memoizeOne(fn);
}
export function memoizeOneWithFlag(fn) {
    let lastArgs = null;
    let lastResult;
    let isFirst = true;
    return (...args) => {
        const res = isEqualArgs(lastArgs, args)
            ? { cached: true, first: isFirst, result: lastResult }
            : {
                cached: false,
                first: isFirst,
                result: (lastArgs = args) && (lastResult = fn(...args))
            };
        isFirst = false;
        return res;
    };
}
export function memoizeAll(fn) {
    const cache = [];
    return (...args) => {
        const entry = cache.find(e => isEqualArgs(e.args, args));
        return (entry || cache[cache.push({ args, result: fn(...args) }) - 1])
            .result;
    };
}
